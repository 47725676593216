import React, { useContext, useState } from "react"
import Breadcrumbs from "../breadcrumbs"
import { graphql, useStaticQuery } from "gatsby"
import {
  Accordion,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap"
import AccordionContext from "react-bootstrap/AccordionContext"
import parse from "html-react-parser"
import { useGetJobsQuery } from "../../redux/mediacore/jobs"
import FormJobs from "./jobsForm"
import jobsImg from '../../../static/jobs/jobs.webp';

const Main = () => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          captcha
        }
        name
        sections {
          joinUs {
            image
            subtitle
            formText
          }
        }
      }
    }
  `)

  const { keys, sections } = realEstate
  const { data: allJobsData } = useGetJobsQuery(6)
  console.log(allJobsData);

  const ctaBtn = jobId => (
    <>
      <button className="btn btn-primary" onClick={() => handleClick(jobId)}>
        Postularme
      </button>
    </>
  )

  const [showForm, setShowForm] = useState(false)

  const ContextAwareToggle = ({ children, eventKey, callback, formState }) => {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey)
      formState?.showForm && formState?.setShowForm(false)
    })

    const isCurrentEventKey = activeEventKey === eventKey

    return (
      <div
        className="accordion-header d-flex justify-content-between align-itmes-center cursor-pointer card-header-wrapper"
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            "m-1 icon-arrow-down " + (isCurrentEventKey ? "up" : "down")
          }
        ></i>
      </div>
    )
  }

  const handleClick = jobId => {
    new Promise(resolve => {
      setShowForm(true)
      resolve()
    }).then(() => {
      setTimeout(() => {
        if (document) {
          document
            ?.querySelector(`#form-id-${jobId}`)
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }, 500)
    })
  }

  return (
    <section className="sumate-main">
      <Container fluid className="content-main">
        <Row>
          <Col xs={12}>
            <Row className="row-wrap">
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Breadcrumbs
                      props={[
                        { name: realEstate.name, route: "/", location: "" },
                        { name: "RRHH", route: "", location: "" },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <h1>
                  {sections?.joinUs?.subtitle} {realEstate.name}
                </h1>
              </Col>
              <Col xs={12}>
                <h2>{sections?.joinUs?.formText}</h2>
              </Col>
              {/* {sections?.joinUs?.image ? ( */}
              <img src={(sections?.joinUs?.image?.length > 0 ? sections?.joinUs?.image : jobsImg)} alt="sumate"/>
              {/* ) : null} */}
              <Col xs={12}>
                <p className="counter mt-5">
                  {allJobsData?.length} búsquedas activas
                </p>
              </Col>
              <div className="jobs-wrapper my-4">
                <Accordion flush>
                  {allJobsData && allJobsData?.length > 0
                    ? allJobsData?.map((job, i) => (
                        <Accordion.Item eventKey={String(i)}>
                          <ContextAwareToggle
                            eventKey={String(i)}
                            formState={{ showForm, setShowForm }}
                          >
                            <div className="d-flex flex-column">
                              <h4 className="position mb-1">{`${job.name} ${
                                job.experience_level !== "Sin especificar"
                                  ? `${job.experience_level}`
                                  : ""
                              }`}</h4>
                              <h4 className="location">{`${job.mode} ${
                                job.mode === "Presencial" || job.mode === "Híbrida"
                                  ? ` -  ${job.location}`
                                  : ""
                              }`}</h4>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Body className="px-0 description">
                            {ctaBtn(job.id)}

                            {job.description && (
                              <div className="description-container my-5">
                                {parse(job.description)}
                              </div>
                            )}
                            {ctaBtn(job.id)}
                            <FormJobs
                              jobData={job}
                              captcha={keys?.captcha}
                              stateProp={showForm}
                              closeBtnAction={() => setShowForm(false)}
                              clientName={realEstate.name}
                              formText={sections?.joinUs?.formText}
                              clientId={6}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ))
                    : null}
                </Accordion>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Main
